"use client";

import Link from "next/link";
import cn from "classnames";
import Lightbox from "yet-another-react-lightbox";
import Inline from "yet-another-react-lightbox/plugins/inline";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import { useRef } from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { FaArrowRight } from "react-icons/fa";
import { useInView } from "framer-motion";

import Button from "@components/ui/form/button";
import { SectionProps } from "@src/types/section";

import ContentfulImage from "./image";

const Section = ({ section, bg = true }: { section: SectionProps; bg?: boolean }) => {
	const ref = useRef(null);
	const isInView = useInView(ref, { once: true });
	return (
		<div
			className={cn("content-container px-4 lg:px-0", {
				"py-8 lg:py-14 ": section.margins,
			})}
			ref={ref}
		>
			<div
				className={cn("grid grid-cols-1 grow justify-center", {
					"lg:grid-cols-2 bg-base-300": section?.sectionImagesCollection?.items?.length,
				})}
			>
				{section?.sectionImagesCollection?.items?.length ? (
					section?.sectionImagesCollection?.items.length > 1 ? (
						<>
							<div
								className={cn({
									"sm:order-2": section?.imagePosition ? 1 : 0,
								})}
							>
								<div
									className={cn("p-8 prose", {
										// "px-4": section?.imagePosition,
										// "px-8": !section?.imagePosition,
									})}
								>
									<h2 className="font-medium" hidden={section?.hideTitle}>
										{section?.sectionTitle}
									</h2>
									{section?.sectionText && <div>{documentToReactComponents(section?.sectionText?.json)}</div>}
									{section?.sectionCta && (
										<Link href={section?.sectionCta?.url} className="btn btn-primary mt-8">
											{section?.sectionCta?.label} <FaArrowRight />
										</Link>
									)}
								</div>
							</div>
							<div
								className={cn({
									"sm:order-1": section?.imagePosition ? 1 : 0,
								})}
							>
								<div className="relative">
									<Lightbox
										slides={section?.sectionImagesCollection?.items.map((slide) => ({
											src: slide.url,
											name: slide.description,
											alt: slide.title,
										}))}
										plugins={[Inline, Slideshow]}
										carousel={{
											padding: 0,
											spacing: 10,
											imageFit: "cover",
										}}
										slideshow={{
											autoplay: true,
											delay: 5000,
										}}
										inline={{
											className: "lg:min-h-[450px] w-full mx-auto object-cover",
											style: {
												aspectRatio: "3 / 2",
											},
										}}
									/>
								</div>
							</div>
						</>
					) : (
						<>
							<div
								className={cn({
									"sm:order-2": section?.imagePosition ? 1 : 0,
								})}
							>
								<div
									className={cn("p-8 prose", {
										// "px-4": section?.imagePosition,
										// "px-8": !section?.imagePosition,
									})}
								>
									<h2 className="font-medium mb-4" hidden={section?.hideTitle}>
										{section?.sectionTitle}
									</h2>
									{section?.sectionText && <div>{documentToReactComponents(section?.sectionText?.json)}</div>}
									{section?.sectionCta && (
										<Link href={section?.sectionCta?.url} className="mt-8">
											<Button>
												{section?.sectionCta?.label} <FaArrowRight />
											</Button>
										</Link>
									)}
								</div>
							</div>
							<div
								className={cn("relative min-h-[350px] lg:min-h-[450px]", {
									"sm:order-1": section?.imagePosition ? 1 : 0,
								})}
							>
								<ContentfulImage
									className="object-cover"
									src={section?.sectionImagesCollection?.items[0]?.url}
									// width={1000}
									// 	height={500}
									loading="lazy"
									fill
									// height={section?.sectionImagesCollection?.items[0]?.height}
									quality={90}
									alt={section?.sectionImagesCollection?.items[0]?.title}
								/>
							</div>
						</>
					)
				) : (
					<div>
						<div className="w-full text-center">
							<h2 className="font-medium" hidden={section?.hideTitle}>
								{section?.sectionTitle}
							</h2>
							{section?.sectionText && (
								<div
									className={cn("max-w-4xl mx-auto prose", {
										"text-center": section?.centerText,
										"text-left": !section?.centerText,
									})}
								>
									{documentToReactComponents(section?.sectionText?.json)}
								</div>
							)}
							{section?.sectionCta && (
								<Link href={section?.sectionCta?.url}>
									<Button className="mt-8">
										{section?.sectionCta?.label} <FaArrowRight />
									</Button>
								</Link>
							)}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default Section;
