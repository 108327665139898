"use client";

import { useRef } from "react";
import { useInView } from "framer-motion";

import Animate from "@components/ui/animation";
import { LogoGridProps } from "@src/types/section";

import ContentfulImage from "./image";

const LogoGrid = ({ section }: { section: LogoGridProps }) => {
	const ref = useRef(null);
	const isInView = useInView(ref, { once: true });

	if (!section?.listCollection?.items?.length) return null;

	return (
		<div className="content-container">
			<Animate>
				<h2 className="py-20 text-center">{section?.name}</h2>
				<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 justify-items-center gap-12 items-center justify-center px-8">
					{section?.listCollection?.items?.map((partner) => (
						<ContentfulImage
							key={JSON.stringify(partner)}
							src={partner?.logo?.url}
							alt={partner.name}
							width={300}
							height={300}
							style={{ maxWidth: "100%", height: "auto" }}
						/>
					))}
				</div>
			</Animate>
		</div>
	);
};

export default LogoGrid;
