/* eslint-disable jsx-a11y/alt-text */
import Image, { ImageProps } from "next/image";

export const contentfulLoader = ({
	src,
	width,
	height,
	quality,
}: {
	src: string;
	width: number;
	height?: number;
	quality?: number;
}) => {
	if (height) return `${src}?fit=scale&w=${width}&h=${height}&q=${quality || 50}`;
	else return `${src}?fit=scale&w=${width}&q=${quality || 50}`;
};

const ContentfulImage = (props: ImageProps) => {
	return <Image loader={contentfulLoader} {...props} />;
};

export default ContentfulImage;
